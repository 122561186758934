"use client";

import { ReactNode } from "react";
import { useMonarchData } from "./MonarchResolver";
import { MonarchTestResult } from "./useMonarchTestResult";

interface MonarchDataConsumerProps<TestResult extends MonarchTestResult> {
  children: (data: TestResult) => ReactNode;
  experimentKey: string; // Require experiment key to identify which experiment data to use
}

/**
 * Consumes data for a specific Monarch experiment and passes it to its children.
 */
export function MonarchDataConsumer<TestResult extends MonarchTestResult>({
  children,
  experimentKey,
}: MonarchDataConsumerProps<TestResult>) {
  // Get data for the specific experiment directly
  const dataToProvide = useMonarchData<TestResult>(experimentKey);
  if (!dataToProvide) {
    return <>{children({} as TestResult)}</>;
  }

  return <>{children(dataToProvide)}</>;
}
