"use client";

import BaseInternalNav from "@/components/InternalNav";
import { NavProps } from "@/components/InternalNav/types";
import { MonarchDataConsumer } from "@/monarch/MonarchDataConsumer";
import { DegreeDetailRedesignTestResult } from "@/monarch/rules/degreeDetailRedesignTraffic/types";
import { validateLocation } from "@/utils/functions/validateLocation";

const DegreeNav = (props: NavProps) => {
  return (
    <MonarchDataConsumer<DegreeDetailRedesignTestResult> experimentKey="useDegreeDetailRedesignTraffic">
      {(monarchData) => {
        const isValidLocation = validateLocation(
          props.locationRestrictions?.allowedIn || [],
          props.locationRestrictions?.blockedIn || [],
        );
        const cta = {
          label: monarchData.data?.stickyCta?.text || props.cta?.label || "",
          url: monarchData.data?.stickyCta?.url || props.cta?.url || "",
          show:
            !monarchData.data?.stickyCta?.hideButton ||
            (props.cta?.show !== false && isValidLocation),
        };
        return (
          <BaseInternalNav
            {...props}
            cta={cta}
            testVariant={monarchData.variant}
          />
        );
      }}
    </MonarchDataConsumer>
  );
};

export default DegreeNav;
